<template>
  <div class="header">
    <img width="25px" alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Jesse Umulisa Katabarwa is an artist, art director, designer, working 
    in the field of visual communication and visual inter-arts interested in publishing either online or offline. 
    His work was shown at NADA Art Fair 2021 Miami and won the MobileCoin Prize. 
      His work has been exhibited as part of the 100 beste plakate exhibition that took place in Austria, Switzerland and 
      at the Kulturforum in Berlin. He's been awarded for best student work at the Weltformat 14 Plakatfestival in Luzern, Switzerland.
      His work has been published by Mold Magazine, Pica, Tunica, IDpure Swiss, and HORT berlin.
      Some of his work has been screened at Virginia Afrikana Film FEST, PHI Centre, Centre de Design UQAM and at la Cinémathèque Québécoise.
      During his time at SSENSE he was part of an highly creative and radical thinking team and realized projects at the frontier of technology, 
      culture and fashion working with international clients such as David Chipperfield Architects. 
      Worked on an array of activations from visual communication to wayfinding, rebranding, editorial and art direction. 
      Selected clients include Arca, Reebok, Virgil Abloh, SSENSE and 032c."/>
    <div class="emailLink">
      <p> 
        <br>
        <a href="mailto: jessekatabarwa@cloud.com" target="_blank" rel="noopener">link to email</a>.
      </p>    
    </div>
  </div>

  <div class="main">
    <div class="artPost">
      <div class="wrapper">
        <img src= "./assets/NYABF_AFILASI.jpg" alt="Afilasi NYABF presentation (2022)" height="100%">
        <img src= "./assets/NYABF_AFILASI2.jpg" alt="Afilasi NYABF presentation (2022)" height="100%">
        <img src= "./assets/NYABF_AFILASI3.jpg" alt="Afilasi NYABF presentation (2022)" height="100%">
      </div>
      <p class="description">Afilasi NYABF presentation (2022)</p> 

      <div class="wrapper">
          <img src= "./assets/seeing1.jpg" alt="Afilasi NYABF presentation (2022)" height="100%">
          <img src= "./assets/seeing2.jpg" alt="ways of seeing and simulacre (2022)" height="100%">

      </div>
          <p class="description"> ways of seeing & "simulacre" (2022)</p> 

      <div class="wrapper">
        <img src= "./assets/diasphora.png" alt="diasphora tryptich (2016)" height="100%">
      </div>
      <p class="description">diasphora tryptich (2016)</p>

      <div class="wrapper">
        <img src= "./assets/hyper.png" alt="hyper-link web native curation (2016)" height="100%">
        <img src= "./assets/hyper0.png" alt="hyper-link web native curation (2016)" height="100%">
      </div>
      <p class="description">hyper-link web native curation (2016)</p>
     
      
    </div>

  </div>
  
 



</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}

</script>

<style>
#app {
  font-family:'Courier New', Courier, monospace, Helvetica Neue, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  margin-top: 5px;
}

.header {
  position: relative;
  top: 5px;
  left:5px;
  display: flex;
  flex-direction: column ;
  align-items: flex-start;
}

.main {
position: relative;
display: block;
}

.emailLink {
  text-align: left;
}

img {
  margin : 5px 5px 0px 5px
}

.description {
  text-transform: capitalize;
  padding-left: 5px;
}

.wrapper{
  width: 100%;
  height: 80vh;
  overflow-x: scroll;
  overflow-y:hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}


</style>
